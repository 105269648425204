import React from "react"
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { LoginApi, UsersApi } from "typescript-axios";
import { Button } from "reactstrap"

import { createUserSso } from '../utils/session'
import { getAxiosParams } from '../utils/client'

import useTranslation from "../hooks/UseTranslation";

const USER_SERVICE = new UsersApi(getAxiosParams())
const LOGIN_SERVICE = new LoginApi(getAxiosParams())

const CustomFacebookLogin = props => {
    const { t } = useTranslation()
    const [disabled, setDisabled] = React.useState(props.disabled)
    const [isGuru, setIsGuru] = React.useState(props.isGuru)
    const [isLoading, setIsLoading] = React.useState(false)

    React.useEffect(
        () => {
            setDisabled(props.disabled)
        }, [props.disabled]
    )

    React.useEffect(
        () => {
            setIsGuru(props.isGuru)
        }, [props.isGuru]
    )

    const handleLoginResponseSso = (response, token) => {
        setIsLoading(true)
        if (response.status == 468) {
            setIsLoading(false)
            response.data = { detail: t("login_error_sso_email_exists") }
            props.onError(response)
        } else if (response.status == 469) {
            setIsLoading(false)
            response.data = { detail: t("login_error_email_not_granted")}
            props.onError(response)
        } else if (response.status == 404) {
            // account does not exist
            if (props.isSignUp) {
                if (isGuru) {
                    // should fetch user details
                    window.FB?.api('/me', 'get', { fields: 'first_name,last_name,email' }, function (data) {
                        props.onSuccessAccountCreated({ firstname: data.first_name, lastname: data.last_name, email: data.email, token })
                    })
                } else {
                    // should create account with SSO - this is without guru here
                    setIsLoading(true)
                    const onError = e => {
                        // only enable button if there was an error
                        setIsLoading(false)
                        props.onError(e)
                    }
                    createUserSso(token, onError, props.onSuccessAccountCreated)
                }
            } else {
                setIsLoading(false)
                props.onUserDoesNotExist()
            }
        } else if (response.status < 200 || response.status >= 300) {
            props.onError(response)
            setIsLoading(false)
        } else {
            // account exists - do login
            USER_SERVICE.readUserMeApiV1UsersMeGet().then(res => props.onSuccess(res.data))
        }
    }

    const ssoResponse = async ssoResp => {
        if (ssoResp.accessToken) {
            const response = await LOGIN_SERVICE.loginAccessTokenSsoApiV1LoginAccessTokenSsoPost("facebook", ssoResp.accessToken)
                .then(res => res)
                .catch(e => e.response)
            handleLoginResponseSso(response, ssoResp.accessToken)
        } else {
            // oauth window was closed
            setIsLoading(false)
        }
    }

    return <FacebookLogin
        appId={process.env.NEXT_PUBLIC_FACEBOOK_ID}
        callback={ssoResponse}
        scopes="email"
        language="fr_FR" // fixme hardcoded
        authType="rerequest"
        disableMobileRedirect={true}
        render={renderProps => (
            <div>
                <Button
                    block
                    color="facebook"
                    size={props.size}
                    disabled={renderProps.isDisabled || renderProps.isProcessing || !renderProps.isSdkLoaded || isLoading || disabled}
                    onClick={() => {
                        setIsLoading(true)
                        renderProps.onClick()
                    }}>
                    <i class="fab fa-facebook-f mr-2" />{props.text ?? t("login_continue_with_fb")}
                </Button>
            </div>
        )}
    />
}

export default CustomFacebookLogin