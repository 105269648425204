
import { Progress } from "reactstrap"
import React from "react"

const ProgressUniform = props => {

    const [progress, setProgress] = React.useState(0)

    React.useEffect(
        () => {
            if (progress >= 100) {
                props.onEnd && props.onEnd()
            }
        }, [progress]
    )

    React.useEffect(
        () => {
            const update = () => {
                setProgress(progress => {
                    if (progress < 100) {
                        progress++
                    }
                    return progress
                })
            }
            const intervalId = setInterval(update, props.timeout / 100)
            return () => clearInterval(intervalId)
        }, []
    )

    return <Progress
        animated
        value={progress}
    />

}

export default ProgressUniform